/*
Theme Name: Delta Services
Theme URI: tbd
Author: Price Weber Marketing
Author URI: https://priceweber.com
Description: Theme created by Price Weber Marketing
Version: 1.2
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: INSERT TAGS HERE SEPARATED BY COMMAS
Text Domain: themename

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.
*/