@import "base-files/_themeHead.scss";
@import "base-files/config";
@import "base-files/mixins";
@import "base-files/reset";
@import "base-files/bootstrap";

//UNIVERSAL STYLES

//COLOR PALETTE
$green: #6ABD45;
$white: #FFFFFF;
$navy-blue: #003373;
$light-blue: #00AEEF;
$yellow: #F7C909;
$dark-red: #C32435;
$dark-navy: #00164B;
$copper: #AE7D06;
$silver: #B0B7BC;
$black: #000000;

//TYPOGRAPHY
$primary-font: 'Roboto Condensed', sans-serif;
$secondary-font: 'Roboto', sans-serif;
