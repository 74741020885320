@import "base-files/base";

//SITE STYLES

html {
  height: 100%;

  body {
    min-height: 100vh;
    font-family: $primary-font;

    img {
      max-width: 100%;
      height: auto;
    }

    .button,
    input[type="submit"]{
      padding: 12px 20px;
      display: inline-block;
      font-size: 8pt;
      font-family: $secondary-font;
      font-weight: 900;
      border-radius: 4px;
      border: 2px solid transparent;
      cursor: pointer;

      &.yellow {
        background-color: $yellow;
        color: $navy-blue;
      }

      &.white {
        background-color: $white;
        border: 2px solid $light-blue;
        color: $light-blue;
        font-size: 10pt;
      }

      &:hover {
        text-decoration: none;

        &.yellow {
          background-color: $navy-blue;
          color: $white;
        }

        &.white {
          background-color: $light-blue;
          color: $white;
        }

      }

    }

    input[type="submit"] {
      background-color: $yellow;
      color: $navy-blue;
    }

    .gform_wrapper .gfield_time_ampm select {
      width: calc(35px + 2em);
    }

    .utilities-menu-mobile {
      padding: 10px 15px;
      background-color: #ccc;
      text-align: center;
      cursor: pointer;
    }

    .utilities-menu-desktop {
      position: absolute;
      right: 0;
      top: -16px;
      cursor: pointer;
      font-size: 12px;
      border: 1px solid #ccc;
      padding: 5px 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #f7f7f7;
      color: $navy-blue;
    }

    .top-banner {
      background-color: $navy-blue;
      padding: 12px 10px;
      text-align: center;
      font-size: 12px;
      color: $white;
      position: relative;
      z-index: 200000;
      cursor: pointer;

      @include responsive(desktop) {
        cursor: unset;
      }

      .top-banner-content {
        padding: 20px 15px;
        position: absolute;
        width: 100%;
        z-index: 100;
        left: 0;
        top: 100%;
        background-color: $navy-blue;

        @include responsive(desktop) {
          display: block !important;
          top: 0;
          background-color: unset;

          .left {
            float: left;
            padding: 0 15px;
            margin-top: -7px;
          }

          .right {
            float: right;
            padding: 0 15px;
            margin-top: -7px;
          }

          p, a {
            display: inline-block !important;
            margin-right: 10px;
            color: $white;
            font-size: 12px;
            margin-top: 0;
          }

        }

        a {
          color: $white;
          display: block;
          margin-bottom: 30px;
          font-weight: 900;

          &:last-of-type {
            margin-bottom: 0;
          }

        }

      }

    }

    .search {
      margin-bottom: 30px;
      display: inline-block;
      position: relative;

      .search-icon,
      .fas.fa-search {
        position: absolute;
        right: 0;
        text-align: center;
        line-height: 46px;
        background-color: $navy-blue;
        color: #FFFFFF;
        width: 40px;
        height: 99%;
        display: block;
        padding: 0;
        top: 0;
        border-radius: 0;

        @include responsive(tablet) {
          line-height: 32px;
          font-size: 12px;
        }

      }

      .fas.fa-search {
        pointer-events: none;
      }

      input {
        padding: 10px 50px 10px 10px;

        @include responsive(tablet) {
          width: 100%;
          padding: 5px 50px 5px 10px;
          font-size: 12px;
        }

      }

    }

    header {
      background-color: $white;
      padding: 15px 0;
      position: relative;
      z-index: 100000;
      height: 75px;

      @include responsive(desktop) {
        overflow: hidden;
        height: auto;
        padding-bottom: 30px;
      }

      .container {
        display: flex;
        align-items: center;
        position: relative;

        @include responsive(desktop) {
          flex-wrap: wrap;
        }

        @media (min-width: 1200px) {
          max-width: 1300px;
        }

      }

      .hamburger {
        width: 25%;
        height: 100%;
        position: relative;

        .line-container {
          position: absolute;
          width: 42px;
          height: 26px;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }

        .line {
          border-bottom: 4px solid $navy-blue;
          width: 40px;
          height: 2px;
          display: block;
          transition: all 500ms ease-in-out;
          position: absolute;
          left: 0;

          &:nth-of-type(1) {
            top: 0;
          }

          &:nth-of-type(2) {
            top: 13px;
          }

          &:nth-of-type(3) {
            top: 26px;
          }

        }

        &.active {

          .line {
            border-bottom-color: $dark-navy;

            &:nth-of-type(1) {
              transform: rotate(45deg);
              top: 10px;
            }

            &:nth-of-type(2) {
              opacity: 0;
            }

            &:nth-of-type(3) {
              transform: rotate(-45deg);
              top: 10px;
            }

          }

        }

      }

      .logo {
        width: 75%;

        @include responsive(desktop) {
          width: 100%;
          margin-bottom: 20px;
        }

        img {
          max-width: 100%;
        }

      }

      .main-menu {
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 2;
        padding: 20px 20px 20px;
        background-color: $light-blue;
        width: 100%;

        &.ng-hide {

          @include responsive(desktop) {
            display: block !important;
            padding: 0;
            margin-bottom: 5px;
            max-height: 25px;
          }

          @include responsive(large) {
            margin-bottom: 0;
          }

        }

        @include responsive(desktop) {
          position: relative;
          top: 0;
          left: auto;
          background-color: transparent;
          width: 100%;
          text-align: right;
          margin-top: 0;
          padding: 0 20px 0 20px;
          height: 100%;
          max-height: 75px;
        }

        .menu {
          list-style: none;
          padding: 30px 20px 50px 20px;
          margin: 0;

          @include responsive(desktop) {
            width: 100%;
            padding: 0;
            display: flex;
          }

          & > li {
            margin-bottom: 20px;
            font-weight: bold;

            @include responsive(desktop) {
              padding: 2px 5px 5px 5px;
              position: relative;
              display: inline-block;
              margin: 0;
              flex: 1;
              text-align: center;

              &:hover,
              &.current-menu-item,
              &.current-menu-parent {
                position: relative;

                &:after {
                  content: '';
                  position: absolute;
                  top: -4px;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: $light-blue;
                  /* Skew */
                  -webkit-transform: skew(-25deg);
                  -moz-transform: skew(-25deg);
                  -o-transform: skew(-25deg);
                  transform: skew(-25deg);
                  z-index: -1;
                }

                .main-link,
                & > a {
                  color: $white;
                  text-decoration: none;
                }

              }

              &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
              }

            }

            .main-link,
            & > a {
              color: $white;
              display: inline-block;
              vertical-align: middle;
              font-size: 16pt;
              line-height: 18pt;
              font-weight: 300;
              text-transform: uppercase;

              @include responsive(desktop) {
                color: $navy-blue;
                cursor: pointer;
                flex: 1;
                font-size: 12px;
                line-height: 12px;
                font-weight: 400;
                padding: 5px 0;
              }

              @include responsive(rachel) {
                font-size: 12px;
              }

            }

            &:last-of-type {
              margin-bottom: 0;
            }

            ul {
              @extend .menu;
              padding-bottom: 15px;
              padding-top: 20px;

              @include responsive(desktop) {
                display: none;
                position: absolute;
                top: 30px;
                left: -6px;
                padding: 0;
                width: calc(100vw - 345px);
              }

              li {
                @extend .main-link;
                display: block;
                position: relative;

                &:before {
                  content: '';
                  position: absolute;
                  left: -20px;
                  top: 0;
                  bottom: 0;
                  height: 2px;
                  border-bottom: 1px solid #FFFFFF;
                  width: 10px;
                  margin: auto 0;
                }

                @include responsive(desktop) {
                  display: inline-block;
                  text-align: center;
                  padding: 0;
                  float: left;
                  margin-right: 20px;
                  position: relative;

                  &:before {
                    display: none;
                  }

                  &:after {
                    content: '';
                    position: absolute;
                    border-left: 1px solid $light-blue;
                    top: 0;
                    left: -10px;
                    height: 120%;
                    /* Skew */
                    -webkit-transform: skew(-25deg);
                    -moz-transform: skew(-25deg);
                    -o-transform: skew(-25deg);
                    transform: skew(-25deg);
                  }

                  &:last-of-type {

                    &:before {
                      content: '';
                      position: absolute;
                      border-left: 1px solid $light-blue;
                      top: 0;
                      right: -10px;
                      height: 100%;
                      /* Skew */
                      -webkit-transform: skew(-25deg);
                      -moz-transform: skew(-25deg);
                      -o-transform: skew(-25deg);
                      transform: skew(-25deg);
                    }

                  }

                  &:hover {

                    &:after {
                      display: none;
                    }

                  }

                  &.current-menu-item {

                    &:after {
                      display: none;
                    }

                  }

                }

                a {
                  text-transform: uppercase;
                  color: $white;

                  @include responsive(desktop) {
                    font-size: 11px;
                    font-weight: 400;
                    color: $navy-blue;
                    line-height: 20px;

                    &:hover {
                      color: $white;
                      text-decoration: none;

                      &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: -11px;
                        right: -11px;
                        bottom: -8px;
                        background: $light-blue;
                        /* Skew */
                        -webkit-transform: skew(-25deg);
                        -moz-transform: skew(-25deg);
                        -o-transform: skew(-25deg);
                        transform: skew(-25deg);
                        z-index: -1;
                        color: $white;
                      }

                    }

                  }

                }

                &.current-menu-item {

                  a {

                    &:after {
                      content: '';
                      position: absolute;
                      top: 0;
                      left: -12px;
                      right: -13px;
                      bottom: -3px;
                      background: $light-blue;
                      /* Skew */
                      -webkit-transform: skew(-25deg);
                      -moz-transform: skew(-25deg);
                      -o-transform: skew(-25deg);
                      transform: skew(-25deg);
                      z-index: -1;
                      color: $white;
                    }

                  }

                }

              }

              &.active {
                max-height: 500px;
                opacity: 1;
                margin-top: 15px;

                @include responsive(tablet) {
                  margin-top: 0;
                }

              }

            }

            &:hover,
            &.item_active,
            &.current-menu-parent {

              ul {
                display: block;
              }

            }

          }

        }

        small {
          color: $white;
          text-align: center;
          display: block;

          @include responsive(desktop) {
            display: none;
          }

        }

      }

    }

    main {

      h1 {
        margin: 0;
      }

      h2 {
        font-size: 42px;
        color: $navy-blue;
        font-weight: 900;
        margin-bottom: 20px;
        line-height: 44px;
      }

      p {
        color: $navy-blue;
        font-size: 16px;
        line-height: 26px;
        font-family: $secondary-font;
        font-weight: 400;
      }

      strong {
        @extend p;
        font-weight: 900;
      }

      .spacer {
        border: 1px solid transparent;
        margin: 50px 0;
      }

      section {

        &.call-to-action {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
          overflow: hidden;

          @include responsive(tablet) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 100px 0;
          }

          .container {
            padding: 0;
          }

          .rotating-images {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-position: center;
            z-index: 0;
            opacity: 0;
            transition: opacity 1s ease-in-out;

            &.active {
              opacity: 1;
            }

          }

          .text-container {
            background-color: rgba(0, 174, 239, 0.85);
            padding: 40px 30px;
            position: relative;
            z-index: 1;

            @include responsive(tablet) {
              background-color: rgba(250, 250, 250, 0.85);
              padding: 70px 30px 60px;
              width: 400px;
            }

            h1 {
              max-width: 50%;
              color: $white;
              font-weight: 900;
              font-size: 22pt;
              line-height: 24pt;
              margin-bottom: 10px;
              font-family: $secondary-font;

              @include responsive(tablet) {
                max-width: unset;
                font-size: 34pt;
                line-height: 34pt;
                color: $navy-blue;
              }

            }

            p {
              margin-top: 20px;
              font-family: $primary-font;
            }

            .button {

              @include responsive(tablet) {
                margin-top: 30px;
              }

            }

            &.three-fourth {

              h1 {
                max-width: 76%;
              }

            }

          }

          &.hero {

            @include responsive(tablet) {
              padding: 50px 0;
              min-height: calc(100vh - 177px);
            }

            .text-container {
              background-color: transparent;
              position: absolute;
              top: 100vh;

              animation: slide-up 2s 1;
              -webkit-animation: slide-up 2s 1;
              animation-fill-mode: forwards;

              animation-delay:3s;
              -webkit-animation-delay:3s; /* Safari and Chrome */
              -webkit-animation-fill-mode: forwards;

              @include responsive(tablet) {
                background-color: transparent;
              }

              h1 {
                max-width: 100%;
                color: #fff;

                @include responsive(tablet) {
                  color: #fff;
                }

                @include responsive(desktop) {
                  font-size: 46pt;
                  line-height: 46pt;
                  color: #fff;
                }

              }

            }

          }

          &.white {

            @include responsive(tablet) {
              padding: 100px 0;
            }

            .text-container {
              background-color: rgba(250, 250, 250, 0.74);
              overflow: hidden;
              padding: 40px 35px;
              display: flex;
              align-items: center;

              @include responsive(tablet) {
                display: block;
              }

              h1 {
                float: left;
                width: 50%;
                color: $navy-blue;
                font-size: 16pt;
                line-height: 20px;
                margin-bottom: 0;

                @include responsive(tablet) {
                  width: unset;
                  float: none;
                  font-size: 34pt;
                  line-height: 34pt;
                }

              }

              .button-container {
                text-align: center;
                width: 50%;

                @include responsive(tablet) {
                  width: unset;
                  text-align: left;
                }

              }

            }

          }

        }

        &.links {

          @include responsive(tablet) {
            display: flex;
            flex-wrap: wrap;
          }

          .link {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include responsive(tablet) {
              width: 50%;
            }

            @include responsive(desktop) {
              width: 33.333%;
              height: calc(100vw / 3);
            }

            .content {
              position: relative;
              z-index: 100;
              margin: 70px 0;
              padding: 40px;

              h2 {
                color: $white;
                font-size: 24pt;
                font-weight: 900;
                margin-bottom: 10px;
                font-family: $secondary-font;

                @include responsive(tablet) {
                  font-size: 30pt;
                }

              }

              p {
                font-family: $primary-font;
              }

              @include responsive(desktop) {

                a.white.button {
                  display: none;
                }

              }

            }

            @include responsive(desktop) {

              &:hover {

                &:before {
                  background-color: rgba(0, 51, 115, 0.65);
                }

                .content {

                  a.white.button {
                    display: inline-block;
                  }

                }

              }

            }

          }

          &.no-bg {

            .container {
              padding: 0;

              @include responsive(tablet) {
                display: flex;
                flex-wrap: wrap;
                padding: 0 30px;
                justify-content: center;
              }

            }

            .link {
              display: flex;

              @include responsive(tablet) {
                width: 100%;
              }

              @include responsive(desktop) {
                align-items: flex-end;
                padding: 30px 25px 0;
                height: auto;
                width: 33.333%;
              }

              &:before {
                display: none;
              }

              .content {
                margin: 40px 0;
                padding: 0 15px;

                h2 {
                  font-size: 36px;
                  line-height: 36px;

                  @include responsive(desktop) {
                    text-align: left;
                  }

                }

                p {
                  color: $white;
                  text-align: center;
                  max-width: 370px;
                  margin-bottom: 20px;
                  font-size: 14px;
                  line-height: 20px;

                  @include responsive(desktop) {
                    text-align: left;
                  }

                }

                .button {

                  &.white {

                    @include responsive(desktop) {
                      float: left;
                      display: block !important;
                    }

                  }

                }

              }

            }

          }

        }

        &.logos {
          padding: 30px 0;

          @include responsive(tablet) {
            padding: 50px 0;
          }

          .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 0;
          }

          .logo {
            padding: 0 5px;
            text-align: center;
            margin-bottom: 20px;

            img {
              width: 100%;
              max-width: 100px;
            }

          }

          &.four-across {

            .logo {
              width: calc(100vw / 4.5);
              max-width: 25%;
            }

          }

          &.two-across {
            padding: 10px 0;

            .logo {
              width: calc( 100vw / 2.5);

              @include responsive(tablet) {
                width: calc( 100vw / 4.5 );
                max-width: 25%;
              }

            }

          }

          &.six-across {

            .logo {
              width: calc(100vw / 4.5);

              @include responsive(desktop) {
                width: calc( 100vw / 6.5 );
                max-width: 16.66667%;
              }

            }

          }

        }

        &.content {
          padding: 30px 0;

          .sidebar {
            padding-bottom: 30px;

            h1 {
              color: $navy-blue;
              font-weight: 900;
              margin-bottom: 20px;
              font-size: 40px;
              margin-top: 0;
            }

            @include responsive(desktop) {
              width: 300px;
              float: left;
              padding-right: 20px;
            }

          }

          .sidebar + .main-content {

            @include responsive(desktop) {
              width: calc(100% - 300px);
              float: right;
              padding-left: 40px;
              padding-top: 15px;
            }

          }

        }

        &.slide-in {

          .slide-content {
            text-align: center;
            background-color: $light-blue;
            position: relative;
            overflow: hidden;
            margin-bottom: 20px;
            padding: 30px 0 10px;

            @include responsive(tablet) {
              overflow: hidden;
            }

            @include responsive(desktop) {
              padding: 0;
            }

            .container {

              @include responsive(desktop) {
                display: flex;
                align-items: center;
                padding: 0;
                position: relative;
              }

            }

            h2 {
              color: $white;
              font-size: 20px;
              line-height: 22px;
              display: block;

              @include responsive(desktop) {
                float: left;
                width: 25%;
                text-align: left;
                padding-left: 10px;
                margin-bottom: 0;
                padding-right: 30px;
              }

            }

            .sliding-content {
              max-height: 0;
              transition: max-height 0.15s ease-out,
              margin 0.15s ease-out;

              @include responsive(tablet) {
                margin: 0 auto;
                padding-left: 20px;
                column-count: 2;
              }

              @include responsive(desktop) {
                float: right;
                max-height: 100%;
                width: 60%;
                column-count: 2;
                max-width: unset;
                padding: 30px 0 30px 20px;
              }

              ul {
                color: $white;
                list-style: none;
                padding: 0;
                margin: 0;
                text-align: left;

                li {
                  padding-bottom: 10px;
                  font-size: 16px;

                  @include responsive(tablet) {
                    opacity: 0;
                    transition: opacity 0.2s ease-out;
                  }

                  @include responsive(desktop) {
                    transition: opacity 1s ease-out;
                  }

                  strong {
                    color: $white;
                  }

                }

              }

              &.active {
                max-height: 2000px;
                transition: max-height 1s ease-in-out;
                margin-top: 20px;

                @include responsive(tablet) {

                  ul {

                    li {
                      opacity: 1;
                      transition: opacity 1200ms ease-in;
                    }

                  }

                }

                @include responsive(desktop) {
                  margin-top: 0;
                  max-height: 100%;
                  transition: none;
                }

              }

            }

            .blocker {
              position: absolute;
              bottom: 0;
              width: 100%;
              left: 0;
              height: 60px;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;

              @include responsive(desktop) {
                top: 0;
                height: 100%;
                width: 140%;
                right: -70%;
                left: auto;
                bottom: 0;
                transition: right 0.70s ease-in-out;

                &.active {
                  right: -135%;
                }

              }

            }

            .slider-click {
              display: block;
              margin: 10px auto 0;
              width: 60px;
              height: 40px;
              background-image: url('./assets/images/down-arrow.svg');
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              z-index: 100;
              transition: transform 0.25s ease-in-out;

              &.rotated {
                transform: rotate(180deg);

                @include responsive(desktop) {
                  transform: rotate(90deg);
                }

              }

              @include responsive(desktop) {
                position: absolute;
                left: 22%;
                top: 0;
                bottom: 0;
                margin: auto;
                transform: rotate(-90deg);
                cursor: pointer;
                transition: transform 0.55s ease-in-out;
              }

            }

          }

        }

        &.callout {
          padding: 40px 0;
          text-align: center;

          h3 {
            font-style: italic;
            font-size: 22px;
            color: $navy-blue;
            margin-bottom: 15px;
          }

          p {
            line-height: 32px;
          }

        }

        &.team-members {
          padding: 30px 0;
          display: none;

          @include responsive(tablet) {
            display: block;
          }

          h2 {
            text-align: center;
          }

          .team-container {
            display: flex;
            flex-wrap: wrap;
            background-color: rgba(0, 174, 239, 0.57);

            .team-member {
              width: 25%;
              height: calc(100vw / 4);
              background-size: cover;
              background-position: center;
              position: relative;

              .team-member-info {
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 12px 10px 10px 10px;
                background-color: $navy-blue;
                color: $white;
                text-align: right;

                @include responsive(desktop) {
                  opacity: 0;
                  transition: opacity 500ms ease-in-out;
                }

                h4 {
                  font-size: 16px;
                  font-family: $primary-font;
                }

                h5 {
                  font-size: 14px;
                  font-weight: 900;
                  font-family: $secondary-font;
                }

              }

              @include responsive(desktop) {

                &:hover {

                  .team-member-info {
                    opacity: 1;
                  }

                }

              }

            }

            .text {
              max-width: 50%;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              padding: 30px 30px;

              h2 {
                color: $white;
                text-align: left;
                max-width: 50%;

                @include responsive(tablet) {
                  font-size: 24px;
                  line-height: 26px;
                }

                @include responsive(desktop) {
                  font-size: 42px;
                  line-height: 44px;
                }

              }

              .button.yellow {
                margin-left: 30px;
              }

            }

          }

        }

        &.leadership {

          @include responsive(desktop) {
            overflow: unset;
          }

          h2 {
            text-align: center;
          }

          .leader-container {

            @include responsive(desktop) {
              display: flex;
              flex-wrap: wrap;
              position: relative;
              justify-content: center;
            }

            .leader {
              margin: 0 20px 30px;

              @include responsive(desktop) {
                width: 25%;
              }
            .leader-bio {
              padding: 20px;
            }
              .arrow-click {
                width: 50px;
                height: 30px;
                position: absolute;
                bottom: 15px;
                right: 10px;
                background-image: url('./assets/images/down-arrow-blue.png');
                background-size: 90% auto;
                background-position: center center;
                background-repeat: no-repeat;
                transition: transform 250ms ease-in-out;
                cursor: pointer;

                &.active {
                  transform: rotate(180deg);
                }

                @include responsive(desktop) {
                  bottom: 70px;
                  right: 20px;
                  background-image: url('./assets/images/down-arrow.svg');
                  transform: rotate(180deg);
                }

              }

              h4 {
                font-size: 18px;
                color: $navy-blue;
                margin-bottom: 0;
                line-height: 18px;

                @include responsive(desktop) {
                  color: $white;
                }

              }

              h5 {
                font-size: 16px;
                font-weight: 900;
                color: $navy-blue;
                line-height: 16px;
                margin-top: 5px;

                @include responsive(desktop) {
                  color: $white;
                }

              }

              .leader-overview {
                height: 221px;
                width: 235px;
                margin: 0 20px 20px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                position: relative;

                @include responsive(tablet) {
                  width: 50vw;
                  height: 50vw;
                }

                @include responsive(desktop) {
                  width: auto;
                  height: calc(100vw / 2);
                  margin-bottom: 0;
                }

                @include responsive(desktop) {
                  height: calc(100vw / 4);
                }

                .leader-info {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  padding: 10px 65px 10px 10px;
                  background-color: rgba(250, 250, 250, 0.7);

                  @include responsive(desktop) {
                    text-align: right;
                    padding: 10px 20px 10px 10px;
                  }

                }

                h4 {
                  font-family: $primary-font;
                  color: $navy-blue;
                }

                h5 {
                  font-family: $secondary-font;
                  color: $navy-blue;
                }

              }

              .leader-details {
                padding: 0 15px;

                @include responsive(desktop) {
                  position: absolute;
                  top: auto;
                  z-index: 10;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  background-color: $light-blue;
                  padding: 0 40px 75px 0;
                  height: calc(100% + 350px);
                  overflow: hidden;
                }

                .image {
                  display: none;

                  @include responsive(desktop) {
                    display: block;
                    height: calc(100% + 75px);
                    width: calc(100vw / 2);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    float: left;
                    margin-right: 20px;
                  }

                  h4 {

                    @include responsive(desktop) {
                      text-align: right;
                      margin-top: 20px;
                      margin-right: 20px;
                    }

                  }

                  h5 {

                    @include responsive(desktop) {
                      color: $white;
                      text-align: right;
                      margin-right: 20px;
                    }

                  }

                }

                .bio-text {

                  @include responsive(desktop) {
                    max-width: 90vw;
                  }

                }

                p {
                  font-size: 14px;
                  line-height: 20px;
                  margin-bottom: 15px;

                  @include responsive(desktop) {
                    color: $white;
                    padding: 15px 0 0 0;
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 20px;
                  }

                  @include responsive(xlarge) {
                    font-size: 16px;
                    line-height: 26px;
                  }

                }

                .arrow-click {
                  display: none;

                  @include responsive(desktop) {
                    display: block;
                    right: 40px;
                    bottom: 30px;
                    transform: rotate(90deg);
                  }

                }

              }

              &.memoriam {

                .leader-overview {
                  position: relative;

                  &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: rgba(0, 51, 115, 0.38);
                    z-index: 0;
                  }

                  .leader-info {
                    background: none;
                    position: absolute;
                    z-index: 2;
                    background-color: rgba(250, 250, 250, 0.7);

                    h4, h5 {
                      color: $navy-blue;
                    }

                  }

                }

              }

            }

          }

        }

        &.callout,
        &.content {

          form {
            margin-bottom: 50px;
            overflow: hidden;

            @include responsive(tablet) {
              margin-top: 30px;
            }

            .left,
            .right {

              @include responsive(tablet) {
                width: 50%;
                float: left;
                padding: 0 10px;
              }

            }

            small {
              text-align:right;
              display: block;
              margin-bottom: 20px;
            }

            label {
              display: block;
              width: 100%;
              @extend p;
              color: $navy-blue;
              text-align: left;
            }

            input[type="text"],
            input[type="tel"],
            input[type="email"],
            select,
            textarea {
              border: 2px solid $light-blue;
              padding: 5px 10px;
              border-radius: 8px;
              background-color: #F1F9FF;
              color: $light-blue;
              width: 100%;
              font-size: 14px;
              height: 45px;

              &::placeholder {
                color: $light-blue;
                font-size: 14px;
              }

            }

            textarea {
              height: 325px;
            }

            input[type="submit"] {
              margin: 40px auto 0;
              display: block;
              padding: 10px 40px;
              font-size: 14px;
              clear: both;
            }

            .error {
              color: red;
              font-size: 12px;
              margin-left: 5px;
            }

          }

          .gform_wrapper {
            max-width: 800px;
            margin: 0 auto;

            form {

              input[type="text"],
              textarea,
              select {
                border: 1px solid #ccc;
                border-radius: 0;
                background-color: #fff;
                color: #000;
                height: auto;
              }

            }

          }

        }

      }

      &.inner-page {

        section {

          &.call-to-action {

            @include responsive(tablet) {
              min-height: calc(100vh - 177px);
              position: relative;

              &:after {
                content: '';
                width: 60px;
                height: 40px;
                position: absolute;
                bottom: 10px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-image: url('./assets/images/down-arrow.svg');
                background-position: center;
                background-repeat: no-repeat;
              }

            }

          }

        }

        &.error-page {

          section {

            &.call-to-action {

              @include responsive(tablet) {

                &:after {
                  display: none;
                }

              }

            }

          }

        }

      }

    }

    footer {
      padding: 50px 0;
      text-align: center;

      @include responsive(tablet) {
        padding-bottom: 10px;
      }

      .logo {
        margin-bottom: 30px;

        @include responsive(tablet) {
          float: left;
          width: 25%;
          text-align: left;
          margin-bottom: 10px;
        }

      }

      .address {
        margin-bottom: 30px;
        font-weight: 900;
        color: $navy-blue;

        @include responsive(tablet) {
          width: 25%;
          float: left;
          clear: both;
          text-align: left;
          font-size: 12px;
        }

      }

      nav {
        margin-bottom: 30px;

        @include responsive(tablet) {
          width: 25%;
          float: left;
          margin-top: -46px;
          padding-left: 40px;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            padding-bottom: 15px;

            @include responsive(tablet) {
              padding-bottom: 0;
              text-align: left;
            }

            a {
              font-weight: 900;
              color: $navy-blue;

              @include responsive(tablet) {
                font-size: 12px;
                line-height: 12px;
              }

            }

          }

        }

        &.footer-nav {

          @include responsive(tablet) {
            width: 33.333%;
            column-count: 2;
          }

        }

        &.legal-nav {

          @include responsive(tablet) {
            width: 25%;
          }

        }

      }

      .search {

        @include responsive(tablet) {
          width: 16.6%;
          float: left;
          margin-top: -40px;
        }

      }

      .logos {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include responsive(tablet) {
          width: 16.667%;
        }

        small {
          color: $navy-blue;
          display: block;
          width: 100%;
          margin-bottom: 10px;

          @include responsive(tablet) {
            font-size: 10px;
            margin-bottom: 0;
          }

        }

        .logo {
          width: 50px;

        }

      }

      .copyright {
        color: $navy-blue;
        font-size: 10px;

        @include responsive(tablet) {
          width: 100%;
          display: block;
          clear: both;
        }

      }

    }

  }

  &.accessible {

    * {
      -moz-filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      filter: gray; /* IE6-9 */
      filter: grayscale(100%);
    }

  }

}

@keyframes slide-up{
    from {top :700px;}
    to {top :0;}
}

@-webkit-keyframes slide-up{
    from {top :700px;}
    to {top :0;}
}